import React, { useEffect, useState, useRef } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import AddIcon from '@mui/icons-material/Add';
import CancelIcon from '@mui/icons-material/Cancel';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { ColorModeContext, tokens } from "../theme";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
    TextField,
    Button,
    MenuItem,
    Modal,
    Tabs, 
    Tab,
    Divider,
  } from '@mui/material';
import axios from 'axios';
import { global_link } from '../Components/Globallink';
import TokenExpiration from '../Auth/TokenExpiration';
import Expiration from '../Components/Expiration';

const api = axios.create({baseURL: global_link});


function Ticketing() {

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [selectedTab, setSelectedTab] = useState(0);
  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };
  const [newticketopen, setNewTicketOpen] = useState(false);
  const [newticketeditopen, setNewTickeEdittOpen] = useState(false);
  const [formData, setFormData] = useState({});
  const [selectedItem, setSelectedItem] = useState(null);
  const [commentopen, setCommentOpen] = useState(false);
  const [commentlistopen, setCommentListOpen] = useState(false);
  const [selectedItemComment, setSelectedItemComment] = useState(null);
  const [comments, setComments] = useState([]);
  const [IndividualValue, setIndividualValue] = useState([]);
  const [UserID, setUserID] = useState([]);
  const [TickeCategoryOpen, setTickeCategoryOpen] = useState(false);
  const [NewSampleOpen, setNewSampleOpen] = useState(false);
  const [NewFollowOpen, setNewFollowOpen] = useState(false);
  const [NewPropOpen, setNewPropOpen] = useState(false);
  const [NewPropViewOpen, setNewPropViewOpen] = useState(false);
  const [NewPropCloseOpen, setNewPropCloseOpen] = useState(false);



  const [TicketCatgoryData, setTicketCatgoryData] = useState([]);

  
  
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 450,
    borderRadius: '10px',
    boxShadow: 24,
    p: 3,
  };


  // CATEGORIES
  const TicketCatgoryDataStatic = [
    { category: 'Login Issues', subcategories: ['OTP issues', 'App not Launching','Forgot Pin'] },
    { category: 'Transaction issues', subcategories: ['Wallet Verification Pending','Pending Transaction','STK push issues','Declined/Failed transactions ','Transaction charges disputes'] },
    { category: 'Reversal issues', subcategories: ['Airtime Reversal', 'Payless to Mpesa', 'Paylesa to Payless', 'Wrong Till Number', 'Payless to Bank'] },
    { category: 'Merchant issues', subcategories: ['Funds Not Reflecting', 'Till/Paybill not accepting payments','Unlisted Billers'] },
    { category: 'Account status', subcategories: ['Suspended', 'Inactive', 'Blocked', 'Restricted'] },
    { category: 'Loyalty points', subcategories: ['unable to redeem points', 'points not accumulating','Enrollment','Redeemable/ offers'] },
    { category: 'Fraud', subcategories: ['Conned', 'Attempted'] },
    { category: 'Feedback', subcategories: ['Suggestions','Complaints','Compliments'] },
    { category: 'Tariffs', subcategories: ['Transaction fees inquiries', 'Transaction charge disputes', 'Maximum/Minimum Limit Queries'] },
    { category: 'Statement', subcategories: ['How to get statement', 'Missing Transactions', 'Unable to retrieve','Certified statements'] },
    { category: 'Fraud Reports', subcategories: ['Conned','Unauthorized Access','Called by Fraudsters', 'Reporting a suspected Fraud','Physical Fraud'] },
    { category: 'App Downloading', subcategories: ['Android Version','IOS Version','App Identity Issues', 'App missing on Play Store'] },
    { category: 'Customer Onboarding', subcategories: ['Sim Binding','KYC Verification Pending','OTP Issues','KYC Rejected'] },
    { category: 'Downtime issues', subcategories: ['App Downtime/Maintenance','M-pesa downtime','Jambo Pay and other partners'] },
    { category: 'Customer support', subcategories: ['Social media handles','WhatsApp number','Email address','Physical location'] },
    { category: 'Promotions and offers', subcategories: ['Current promotions','Upcoming offers','Merchandise request'] },
    { category: 'Others', subcategories: ['Time queries','Other provider issues','NRG queries','Non-service related issues','Non-Compatible Devices'] }
    // Add more categories and subcategories as needed
  ];

  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedSubcategory, setSelectedSubcategory] = useState('');

  const handleInputCategoryChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
    if (name === 'category') {
      setSelectedCategory(value);
      setSelectedSubcategory(''); // Reset subcategory when category changes
    } else if (name === 'subcategory') {
      setSelectedSubcategory(value);
    }
  };

  const handleInputCategoryModChange = (event) => {
    const { name, value } = event.target;
    setSelectedItem((prevFormState) => ({
      ...prevFormState,
      [name]: value,
    }));
    if (name === 'category') {
      setSelectedCategory(value);
      setSelectedSubcategory(''); // Reset subcategory when category changes
    } else if (name === 'sub_category') {
      setSelectedSubcategory(value);
    }
  };

  const fetchTicketData = async () => {
    try {
      const accessToken = localStorage.getItem("access_token");
      const headers = {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      };
      const response = await api.get('/alltickets/',
        { params: {
          search: searchText,
          status: selectStatus,
	  page: controller.page,
          size: controller.rowsPerPage,
        },
          headers,
          withCredentials: true,
        }
      );
      const data = response.data;
      setFilteredData(data.results);
      setTotalItems(data.count);
    } catch (error) {
      console.error(error);
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  // ticketblastingmail
  // const handleNewTicketSubmit = (event) => {
  //   event.preventDefault();
  //   api.post('/addticket/', formData,{ withCredentials: true})
  //     .then((response) => {
  //       fetchTicketData();
  //       setNewTicketOpen(false);
        
  //     })
  //     .catch((error) => {
  //       console.error(error);
  //     });
  // };

  const handleNewTicketSubmit = (event) => {
    event.preventDefault();

    api.post('/addticket/', formData, { withCredentials: true })
      .then((response) => {
        fetchTicketData();
        setNewTicketOpen(false);

        const formData = new FormData(event.target);
        const subject = formData.get('subject');
        const description = formData.get('description');
        const status = formData.get('status');
        const name = formData.get('name');
        const priority = formData.get('priority');
        const id_no = formData.get('id_no');
        const amount = formData.get('amount');
        const channel = formData.get('channel');
        const phone_number = formData.get('phone_number');
        const mail_to = formData.get('mail_to');
        const assigned_to = formData.get('mail_to');
        const message = `Subject: ${subject}\nName: ${name}\nStatus: ${status}\nPhone Number: ${phone_number}\nID Number: ${id_no}\nChannel: ${channel}\nAmount: ${amount}\nPriority: ${priority}\nDescription: ${description}\nAssigned To: ${assigned_to}`;
        const blastingMailParams = {message: message, subject: subject, mail_to: mail_to }
        api.post('/ticketblastingmail/', blastingMailParams, { withCredentials: true })
          .then((anotherResponse) => {
            console.log(anotherResponse);
          })
          .catch((anotherError) => {
            console.error(anotherError);
          });
      })
      .catch((error) => {
        console.error(error);
      });
  };
  

  const handleEditTicketSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const id = formData.get('id');
    api.put(`/updateticket/${id}`, formData,{ withCredentials: true})
      .then((response) => {
        fetchTicketData();
        fetchTicketAssignedData();
        setNewTickeEdittOpen(false);
      })
      .catch((error) => {
        console.error(error);
      });
  };


  const handleNewCommentSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    api.post('/addcomment/', formData,{ withCredentials: true})
      .then((response) => {
        
        setCommentOpen(false);
        setNewTickeEdittOpen(false);
      })
      .catch((error) => {
        console.error(error);
      });
  };


  // TABLE 1
  const [searchText, setSearchText] = useState('');
  const [selectStatus, setSelectStatus] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const [totalItems, setTotalItems] = useState(0);

  const [controller, setController] = useState({
    page: 1,
    rowsPerPage: 10
  });
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    fetchTicketData();
  }, [searchText,selectStatus, controller]);


  const handleChangePage = (event, newPage) => {
    setController({
      ...controller,
      page: newPage
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setController({
      ...controller,
      rowsPerPage: parseInt(event.target.value, 10),
      page: 1
    });
  };

  const handleRowClick = (item) => {
    setSelectedItem(item);
    setNewTickeEdittOpen(true);
  };

  const handleInputModChange = (event) => {
    const { name, value } = event.target;
    setSelectedItem((prevFormState) => ({
      ...prevFormState,
      [name]: value,
    }));
  };


  const handleAddCommentClick = (tckid) => {
    setCommentOpen(true);
    setSelectedItemComment(tckid);
  };

  const handleCommentListClick = (tckid) => {
    setCommentListOpen(true);
    fetchComments(tckid);
    

  };
  

  const fetchComments = (value) => {
    const accessToken = localStorage.getItem("access_token");
    const headers = {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    };
      api.get(`/getcomments/${value}`,
      {
        headers,
        withCredentials: true,
      }).then(response => {
        setComments(response.data);
      })
      .catch(error => {
        console.error(error);
      })
  };


   const priorityColors = (priority) => {
    switch (priority) {
      case 'Critical':
        return 'red';
      case 'High':
          return 'orange';
      case 'Medium':
        return '#f24602';
      case 'Normal':
        return 'blue';
      case 'Urgent':
        return 'red';
      case 'Escalated':
        return 'purple';
      default:
        return 'inherit';
    }
  };

  const statusColors = (status) => {
    switch (status) {
      case 'Open':
        return 'red';
      case 'Assigned':
        return 'orange';
      case 'In Progress':
        return '#f24602';
      case 'Pending':
        return '#082a45';
      case 'On Hold':
        return 'red';
      case 'Resolved':
        return 'green';
      case 'Closed':
          return 'darkgreen';
      case 'Done':
        return 'darkgreen';
      case 'Reopened':
          return 'darkred';
      case 'Escalated':
          return 'lightred';
      case 'Awaiting Customer Feedback':
          return 'purple';
      default:
        return 'inherit';
    }
  };

  useEffect(() => {
    const accessToken = localStorage.getItem('access_token');
    const headers = {
    'Authorization': `Bearer ${accessToken}`,
    'Content-Type': 'application/json',
    };
    const indivvalue = "";
    const params = {search : indivvalue}
    api.post(`/getusers/`,params,{
      headers,
      withCredentials: true,
    })
      .then(response => {
        const indivresultdata = response.data;
        const filteredIndResults = indivresultdata.filter(item =>
          item.user_name.toLowerCase().includes(indivvalue.toLowerCase()) || 
          item.user_id.toLowerCase().includes(indivvalue.toLowerCase()) || 
          item.user_extension.toLowerCase().include(indivvalue.toLowerCase())
        );
        setIndividualValue(filteredIndResults);
      })
      .catch(error => {
        console.error(error);
      });

      api.get('/user/', {
        headers,
        withCredentials: true,
      })
        .then(response => {
    setUserID(response.data.user_id)
        });
    }, []);

      const [searchTicketText, setSearchEmailText] = useState('');
      const [filteredTicketData, setFilteredAssignedData] = useState([]);
      const [totalTicketItems, setTotalAssignedItems] = useState(0);
      const [ticketcontroller, setTicketController] = useState({
        ticketpage: 1,
        ticketrowsPerPage: 10
      });
      
    
      // const fetchTicketAssignedData = async () => {
      //   try {
      //     const accessToken = localStorage.getItem("access_token");
      //     const headers = {
      //       Authorization: `Bearer ${accessToken}`,
      //       "Content-Type": "application/json",
      //     };
      //     const response = await api.get('/assignedticket/',
      //       { params: {
      //         search: searchTicketText,
      //         page: ticketcontroller.ticketpage,
      //         size: ticketcontroller.ticketrowsPerPage,
      //       },
      //         headers,
      //         withCredentials: true,
      //       }
      //     );
      //     const data = response.data;
      //     setFilteredAssignedData(data.results);
      //     setTotalAssignedItems(data.count);
      //   } catch (error) {
      //     console.error(error);
      //   }
      // };
    
      // useEffect(() => {
      //   fetchTicketAssignedData();
      // }, [searchTicketText, ticketcontroller]);



      const fetchTicketAssignedData = async () => {
        try {
          const accessToken = localStorage.getItem("access_token");
          const headers = {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          };
          const response = await api.get('/alltickets/',
            { params: {
              search: 'Escalated',
              page: ticketcontroller.ticketpage,
              size: ticketcontroller.ticketrowsPerPage,
            },
              headers,
              withCredentials: true,
            }
          );
          const data = response.data;
          setFilteredAssignedData(data.results);
          setTotalAssignedItems(data.count);
        } catch (error) {
          console.error(error);
        }
      };
    
      useEffect(() => {
        fetchTicketAssignedData();
      }, [searchTicketText, ticketcontroller]);
    
    
      const handleTicketRowClick = (item) => {
        setSelectedItem(item);
      };
    
      const handleTicketChangePage = (event, newPage) => {
        setTicketController({
          ...ticketcontroller,
          ticketpage: newPage
        });
      };
    
      const handleTicketChangeRowsPerPage = (event) => {
        setTicketController({
          ...ticketcontroller,
          ticketrowsPerPage: parseInt(event.target.value, 10),
          ticketpage: 1
        });
      };
    

      
    
  
  
 


        const fetchTicketCategoryData = () => {
          const accessToken = localStorage.getItem("access_token");
          const headers = {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          };
          api.get('/ticketcategory/', {
            headers,
            withCredentials: true,
          })
            .then(response => {
              setTicketCatgoryData(response.data);
            })
            .catch(error => {
              console.error(error);
            });
  
        }

        useEffect(() => {
          fetchTicketCategoryData();
        }, []);

      const handleTicketCategorySubmit = (event) => {
        event.preventDefault();
        const formData = new FormData(event.target);
        api.post('/addticketcategory/', formData,{ withCredentials: true})
          .then((response) => {
            setTickeCategoryOpen(false);
            fetchTicketCategoryData();
          })
          .catch((error) => {
            console.error(error);
          });
      };


      const handleDeleteTicketCategorySubmit = (id) => {
        api.delete(`/deleteticketcategory/${id}`,{ withCredentials: true})
          .then((response) => {
            fetchTicketCategoryData();
          })
          .catch((error) => {
            console.error(error);
          });
      };



      const getTicketAge = (createdAt,status) => {
        const currentDate = new Date();
        const createdAtDate = new Date(createdAt);
        // Calculate the difference in milliseconds
        const differenceInMilliseconds = currentDate - createdAtDate;

        // Convert milliseconds to seconds
        const differenceInSeconds = Math.floor(differenceInMilliseconds / 1000);
        if (differenceInSeconds < 60) {
          // if(status!='Escalated'){
          //   return <b style={{ color: 'green' }}>{differenceInSeconds} seconds ago</b>;
          // }else{
          //   return <b style={{ color: 'green' }}>{differenceInSeconds} seconds ago</b>;
          // }
          return `${differenceInSeconds} seconds ago`;
        } else if (differenceInSeconds < 3600) {
            const differenceInMinutes = Math.floor(differenceInSeconds / 60);
            // if(status!='Escalated'){
            //   return <b style={{ color: 'green' }}>{differenceInMinutes} minutes ago</b>;
            // }else{
            //   return <b style={{ color: 'green' }}>{differenceInMinutes} minutes ago</b>;
            // }
            // return <b style={{ color: 'green' }}>{differenceInMinutes} minutes ago</b>;
            return `${differenceInMinutes} minutes ago`;
        } else if (differenceInSeconds < 86400) {
            const differenceInHours = Math.floor(differenceInSeconds / 3600);
            // if(status!='Escalated'){
            //   return <b style={{ color: 'green' }}>{differenceInHours} hours ago</b>;
            // }else{
            //   return <b style={{ color: 'green' }}>{differenceInHours} hours ago</b>;
            // }
            return `${differenceInHours} hours ago`;
        } else {
          const differenceInDays = Math.floor(differenceInSeconds / 86400);
          // if(status!='Escalated' & differenceInDays>2){
          //   return <b style={{ color: 'red' }}>{differenceInDays} days ago</b>;
          // }else{
          //   return <b style={{ color: 'orange' }}>{differenceInDays} days ago</b>;
          // }
            return `${differenceInDays} days ago`;
        }
    };




  const getTicketAgeHighLight = (createdAt, status, substatus) => {
    const currentDate = new Date();
    const createdAtDate = new Date(createdAt);
  
    const differenceInMilliseconds = currentDate - createdAtDate;
    const differenceInSeconds = Math.floor(differenceInMilliseconds / 1000);
    const differenceInMinutes = Math.floor(differenceInSeconds / 60);
    const differenceInHours = Math.floor(differenceInSeconds / 3600);
    const differenceInDays = Math.floor(differenceInSeconds / 86400);
  
    let backgroundColor;

    if (status === 'Escalated' && substatus === 'Done') {
      backgroundColor = '';
    }else{

      if (status === 'Escalated' && substatus === 'Open') {
        // backgroundColor = '#FF9A98';
        if(differenceInMinutes > 60 && differenceInMinutes <= 120){
          backgroundColor = '#DAF7A6';
        }else if(differenceInMinutes >= 2){
          backgroundColor = '#FF9A98';
        }else{
          backgroundColor = '';
        }
      }else if(status === 'Escalated' && substatus === 'On Hold'){
        // backgroundColor = '#E8CFCA';
        if(differenceInMinutes > 60 && differenceInMinutes <= 120){
          backgroundColor = '#DAF7A6';
        }else if(differenceInMinutes >= 2){
          backgroundColor = '#FF9A98';
        }else{
          backgroundColor = '';
        }
      }else if(status === 'Escalated' && substatus === 'In Progress'){
        // backgroundColor = '#DAF7A6';
        if(differenceInMinutes > 60 && differenceInMinutes <= 120){
          backgroundColor = '#DAF7A6';
        }else if(differenceInMinutes >= 2){
          backgroundColor = '#FF9A98';
        }else{
          backgroundColor = '';
        }
      }

      // if (differenceInSeconds < 60) {
      //   // Seconds
      //   if (status === 'Escalated' && substatus !== 'Done'){
      //     backgroundColor = 'lightgreen';
      //   }else{
      //     backgroundColor = '';
      //   }
      // } else if (differenceInSeconds < 3600) {
      //   // Minutes
      //   if (status === 'Escalated' && substatus !== 'Done' && differenceInMinutes>30){
      //     backgroundColor = 'lightorange';
      //   }else{
      //     backgroundColor = '';
      //   }
      // } else if (differenceInSeconds < 86400) {
      //    // Hours
      //    if (status === 'Escalated' && substatus !== 'Done' && differenceInHours>48){
      //     backgroundColor = 'red';
      //   }else{
      //     backgroundColor = '';
      //   }
      // } else {
      //   // Days
      // if (status === 'Escalated' && substatus !== 'Done' && differenceInDays>2){
      //   backgroundColor = 'lightred';
      // }else{
      //   backgroundColor = '';
      // }

      // }



      // if (differenceInSeconds < 60) {
      //   if (status === 'Escalated' && substatus !== 'Done') {
      //     backgroundColor = 'lightgreen';
      //   }else {
      //     backgroundColor = '';
      //   }
      // } else if (differenceInSeconds < 3600) {
      //   if (status === 'Escalated' && substatus !== 'Done' && differenceInSeconds > 3600) {
      //     backgroundColor = 'lightorange';
      //   }else {
      //     backgroundColor = 'lightorange';
      //   }
      // } else if (differenceInSeconds < 86400) {
      //   if (status === 'Escalated' && substatus !== 'Done' && differenceInSeconds > 86400) {
      //     backgroundColor = 'lightred';
      //   }else {
      //     backgroundColor = 'lightred';
      //   }
      // } else {
      //   const differenceInDays = Math.floor(differenceInSeconds / 86400);
      //   if (status == 'Escalated' && substatus !== 'Done'  && differenceInDays > 2) {
      //     backgroundColor = 'lightred';
      //   } else {
      //     backgroundColor = 'lightorange';
      //   }
      // }


    }
  

  
    return backgroundColor;
  };
  

  useEffect(() => {
    const handleTicketRefresh = () => {
      fetchTicketData()
    };
    // Set up an interval to reload functions every 10 seconds
    const refreshInterval = setInterval(handleTicketRefresh, 60000);
    // If you need to stop the interval (e.g., when unmounting the component), use clearInterval
    return () => clearInterval(refreshInterval);
  }, []);

  return (
    <Box sx={{marginTop:'60px',paddingLeft:'10px', flexGrow: 1, p: 3}}>
      <TokenExpiration/>
      <Expiration/>
    <Typography variant="h6" paddingBottom={2}>Ticketing</Typography>
    <div>
      <Tabs value={selectedTab} onChange={handleTabChange}   textColor="secondary"
  indicatorColor="secondary"
  variant="fullWidth"
  sx={{ backgroundColor: `${colors.primary[700]} !important`}}>
        <Tab label="All Tickets" onClick={fetchTicketData} />
      </Tabs>
      <Divider/>

      {selectedTab === 0 && (
        <Box sx={{marginTop:'20px',paddingLeft:'10px', flexGrow: 1,}}>
          <Typography variant="body1">
            <Box display='flex' justifyContent='right'>
            <Button variant='contained'
    sx={{
      backgroundColor: colors.blueAccent[600],
      color: colors.grey[100],
      fontSize: "13px",
      fontWeight: "bold",
      padding:'8px',
      height: '35px',
      mr: "15px;"
    }}
    onClick={() => fetchTicketData()}>
      <AutorenewIcon sx={{ mr: "5px" }} /> Refresh</Button>

<Button variant='contained'
    sx={{
      backgroundColor: colors.blueAccent[600],
      color: colors.grey[100],
      fontSize: "13px",
      fontWeight: "bold",
      padding:'8px',
      height: '35px',
      mr: "15px;"
    }}
    onClick={() => setNewTicketOpen(true)}>
      <AddIcon sx={{ mr: "5px" }} /> New Ticket</Button>
              </Box>
          
              <Grid container spacing={2} p={2}>
              <TextField
        label="Search"
        variant="outlined"
        value={searchText}
        onChange={(e) => setSearchText(e.target.value)}
        style={{ marginBottom: '16px' }}
      />

<TextField
        label="Ticket Status"
        select
        value={selectStatus}
        onChange={(e) => setSelectStatus(e.target.value)}
        style={{ marginBottom: '16px', marginLeft:'20px',width:'150px' }}
      >
        <MenuItem selected disabled value="">Select / Reset Ticket Status</MenuItem>
        <MenuItem value="">Reset Status</MenuItem>
        <MenuItem value="Open">Open</MenuItem>
        <MenuItem value="In Progress">In Progress</MenuItem>
        <MenuItem value="Resolved">Resolved</MenuItem>
        <MenuItem value="Reopened">Reopened</MenuItem>
        <MenuItem value="Escalated">Escalated</MenuItem>
        <MenuItem value="Awaiting Customer Feedback">Awaiting Customer Feedback</MenuItem>
        </TextField>

{/*      
        <form sx={{display: 'inline !mportant'}}>
        <TextField
              name="start_date"
              type="date"
              onChange={handleInputChange}
              fullWidth
              // sx={{marginTop:"10px"}}
              style={{ marginBottom: '16px', marginLeft:'20px',width:'130px' }}
              variant="outlined"
              required
            />
            
            <TextField
              name="end_date"
              type="date"
              onChange={handleInputChange}
              fullWidth
              // sx={{marginTop:"10px"}}
              style={{ marginBottom: '16px', marginLeft:'20px',width:'130px' }}
              variant="outlined"
              required
            />

<Button
          variant="contained"
          fullWidth
          type="submit"
          style={{padding:'15px',marginBottom: '16px', marginLeft:'20px',width:'130px',background: colors.greenAccent[700] }}

        >
         Download
        </Button>
        </form> */}

</Grid>
	      <TableContainer>
        <Table>
          <TableHead sx={{backgroundColor: colors.primary[600],borderRadius: '5px 5px 0 0 !important'}}>
            <TableRow>
            <TableCell>Ticket No</TableCell>
              <TableCell>Creator</TableCell>
              <TableCell>Subject</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>ID No</TableCell>
              <TableCell>Phone Number</TableCell>
              <TableCell>Amount</TableCell>
              <TableCell>Priority</TableCell>
              <TableCell>Channel</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Assigned</TableCell>
              <TableCell>Age</TableCell>
              <TableCell>Created</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody sx={{backgroundColor: colors.primary[700]}}>
            {filteredData
              .map((row, index) => (
                <TableRow key={row.id} sx={{ backgroundColor: getTicketAgeHighLight(row.created_at, row.status, row.sub_status) }} >
                  <TableCell>{row.id}</TableCell>
                  <TableCell>{row.user_name}</TableCell>
                  <TableCell>{row.subject}</TableCell>
                  <TableCell>{row.name}</TableCell>
                  <TableCell>{row.id_no}</TableCell>
                  <TableCell>{row.phone_number}</TableCell>
                  <TableCell>{row.amount}</TableCell>
                  <TableCell sx={{ color: priorityColors(row.priority), fontWeight: 'bold',}}>{(row.priority).toUpperCase()}</TableCell>
                  <TableCell>{row.channel}</TableCell>
                  <TableCell sx={{ color: statusColors(row.status), fontWeight: 'bold',}}>{row.status}</TableCell>
                  <TableCell>{row.assigned_to}</TableCell>
		          <TableCell>{getTicketAge(row.created_at,row.status)}</TableCell>
                  <TableCell>{row.created_at.slice(0, -16).replace('T', ' ')}</TableCell>
                  <TableCell><Button key={row.id} variant='contained' sx={{backgroundColor: colors.blueAccent[600]}} onClick={() => handleRowClick(row)}><VisibilityIcon/></Button></TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10,25,50,100]}
        component="div"
        count={totalItems}
        rowsPerPage={controller.rowsPerPage}
        page={controller.page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        sx={{backgroundColor: colors.primary[600],borderRadius: '0 0 5px 5px'}}
      />

        </Typography>



        <Modal
        open={newticketopen}
        onClose={() => setNewTicketOpen(false)}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box backgroundColor={colors.primary[700]} sx={style}>
        <div className="modal-content">
            <Box sx={{display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <h3 id="modal-title">New Ticket</h3>
            <Box display="flex" justifyContent="right">
        <CancelIcon onClick={() => setNewTicketOpen(false)} sx={{cursor:"pointer"}}/>
        </Box>
            </Box>
          
          <form onSubmit={handleNewTicketSubmit}>
          <Grid container spacing={1}>

          <Grid item xs={12}>
<TextField
              label="Subject"
              name="subject"
              onChange={handleInputChange}
              fullWidth
              variant="outlined"
              required="true"
              sx={{marginTop:"5px"}}
            />
</Grid>

<Grid item xs={6}>
<TextField
              label="Name"
              name="name"
              onChange={handleInputChange}
              fullWidth
              variant="outlined"
              required="true"
              sx={{marginTop:"5px"}}
            />
</Grid>

<Grid item xs={6}>
<TextField
              label="ID Number"
              name="id_no"
              onChange={handleInputChange}
              fullWidth
              variant="outlined"
              required="true"
              sx={{marginTop:"5px"}}
            />
</Grid>


<Grid item xs={6}>
<TextField
              label="Phone Number"
              name="phone_number"
              onChange={handleInputChange}
              fullWidth
              variant="outlined"
              required="true"
              sx={{marginTop:"5px"}}
            />
</Grid>

<Grid item xs={6}>
<TextField
              label="Amount"
              name="amount"
              onChange={handleInputChange}
              fullWidth
              variant="outlined"
              required="true"
              sx={{marginTop:"5px"}}
            />
</Grid>

<Grid item xs={6}>
<TextField
        fullWidth
        select
        label="Ticket Priority"
        name="priority"
        onChange={handleInputChange}
        required="true"
        sx={{marginTop:"5px"}}
      >
        <MenuItem value="">Select Ticket Priority</MenuItem>
        <MenuItem value="Critical">Critical ( P 1 )</MenuItem>
        <MenuItem value="High">High ( P 2 )</MenuItem>
        <MenuItem value="Medium">Medium ( P 3 )</MenuItem>
      </TextField>
</Grid>


<Grid item xs={6}>
<TextField
        fullWidth
        select
        label="Ticket Channel"
        name="channel"
        onChange={handleInputChange}
        required="true"
        sx={{marginTop:"5px"}}
      >
        <MenuItem value="">Select Ticket Channel</MenuItem>
        <MenuItem value="Phone Call">Phone Call</MenuItem>
        <MenuItem value="WhatsApp">WhatsApp</MenuItem>
        <MenuItem value="Email">Email</MenuItem>
        <MenuItem value="Facebook">Facebook</MenuItem>
        <MenuItem value="Twitter">Twitter</MenuItem>
        <MenuItem value="Instagram">Instagram</MenuItem>
        <MenuItem value="Chatbot">Chatbot</MenuItem>
      </TextField>
</Grid>

<Grid item xs={6}>
<TextField
        fullWidth
        select
        label="Ticket Status"
        name="status"
        onChange={handleInputChange}
        required="true"
        sx={{marginTop:"5px"}}
      >
        <MenuItem value="">Select Ticket Status</MenuItem>
        <MenuItem value="Open">Open</MenuItem>
        <MenuItem value="In Progress">In Progress</MenuItem>
        <MenuItem value="Resolved">Resolved</MenuItem>
        <MenuItem value="Reopened">Reopened</MenuItem>
        <MenuItem value="Escalated">Escalated</MenuItem>
        <MenuItem value="Awaiting Customer Feedback">Awaiting Customer Feedback</MenuItem>
      </TextField>
</Grid>

<Grid item xs={6}>
<TextField
        fullWidth
        select
        label="Assign To"
        name="mail_to"
        onChange={handleInputChange}
        required="true"
        sx={{marginTop:"5px"}}
      >
        <MenuItem value="" disabled>Select</MenuItem>
        <MenuItem value="jzakayo@visionafrikasacco.co.ke">Marketing</MenuItem>
        <MenuItem value="kipkirui@visionafrikasacco.co.ke">Operations</MenuItem>
        <MenuItem value="btheuri@visionafrikasacco.co.ke">IT</MenuItem>
        <MenuItem value="susankamau@visionafrikasacco.co.ke">Susan Kamau</MenuItem>
        <MenuItem value="finance@visionafrikasacco.co.ke">Finance</MenuItem>
        <MenuItem value="jkionjogu@visionafrikasacco.co.ke">Credit Mgr</MenuItem>
        <MenuItem value="awambugu@visionafrikasacco.co.ke">Branch Operations Officer</MenuItem>
        <MenuItem value="njagi@visionafrikasacco.co.ke">BM nakuru</MenuItem>
        <MenuItem value="ngure@visionafrikasacco.co.ke">BM Gilgil</MenuItem>
        <MenuItem value="zmugoya@visionafrikasacco.co.ke">BM Molo</MenuItem>
        <MenuItem value="sammyk@visionafrikasacco.co.ke">BM Naivasha</MenuItem>
        <MenuItem value="maureen@visionafrikasacco.co.ke">Operations Officer Molo</MenuItem>
        <MenuItem value="jngutwa@visionafrikasacco.co.ke">Operations Officer Gilgil</MenuItem>
        <MenuItem value="nkagai@visionafrikasacco.co.ke">Operations Officer Naivasha</MenuItem>
        <MenuItem value="pwanjohi@visionafrikasacco.co.ke">Recovery Mgr</MenuItem>
      </TextField>
</Grid>

<Grid item xs={12}>
          <TextField
            multiline
            rows={3}
            label="Ticket Description"
            placeholder="Ticket Description"
            variant="outlined"
            fullWidth
            name="description"
            onChange={handleInputChange}
            sx={{marginTop:"5px"}}
          />
        </Grid>

<Grid item xs={12}>
            <Button type="submit" variant="contained" sx={{padding:"10px",marginTop:"10px",color:'white',backgroundColor: colors.blueAccent[600]}}>Submit Ticket</Button>
            </Grid>

            </Grid>
          </form>
        </div>
        </Box>
      </Modal>



      


        </Box>




      )}

 



<Modal
        open={newticketeditopen}
        onClose={() => setNewTickeEdittOpen(false)}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box backgroundColor={colors.primary[700]} sx={style}>
        <div className="modal-content">
            <Box sx={{display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <h3 id="modal-title">Edit Ticket No #{selectedItem && (selectedItem.id)}</h3>
            <Box display="flex" justifyContent="right">
        <CancelIcon onClick={() => setNewTickeEdittOpen(false)} sx={{cursor:"pointer"}}/>
        </Box>
            </Box>
          
          <form onSubmit={handleEditTicketSubmit}>
          {selectedItem && (
          <Grid container spacing={1}>






          <Grid item xs={6}>
<TextField
              label="Description"
              name="subject"
              onChange={handleInputModChange}
              fullWidth
              variant="outlined"
              required="true"
              value={selectedItem.subject || ""}
            />
            <TextField    
              type="hidden"
              name="id"
              value={selectedItem.id}
              style={{ display: 'none' }}
              required="true"
            />
</Grid>

<Grid item xs={6}>
<TextField
              label="Name"
              name="name"
              onChange={handleInputModChange}
              fullWidth
              variant="outlined"
              required="true"
              value={selectedItem.name || ""}
            />
</Grid>

<Grid item xs={6}>
<TextField
              label="ID Number"
              name="id_no"
              onChange={handleInputModChange}
              fullWidth
              variant="outlined"
              required="true"
              value={selectedItem.id_no || ""}
            />
</Grid>

<Grid item xs={6}>
<TextField
              label="Phone Number"
              name="phone_number"
              onChange={handleInputModChange}
              fullWidth
              variant="outlined"
              required="true"
              sx={{marginTop:"5px"}}
              value={selectedItem.phone_number || ""}
            />
</Grid>

<Grid item xs={6}>
<TextField
              label="Amount"
              name="amount"
              onChange={handleInputModChange}
              fullWidth
              variant="outlined"
              required="true"
              value={selectedItem.amount || ""}
            />
</Grid>





<Grid item xs={6}>
<TextField
        fullWidth
        select
        label="Ticket Channel"
        name="channel"
        onChange={handleInputModChange}
        required="true"
        sx={{marginTop:"5px"}}
        value={selectedItem.channel || ""}
      >
        <MenuItem value="">Select Ticket Channel</MenuItem>
        <MenuItem value="Phone Call">Phone Call</MenuItem>
        <MenuItem value="Email">Email</MenuItem>
        <MenuItem value="WhatsApp">WhatsApp</MenuItem>
        <MenuItem value="Facebook">Facebook</MenuItem>
        <MenuItem value="Twitter">Twitter</MenuItem>
        <MenuItem value="Instagram">Instagram</MenuItem>
        <MenuItem value="Chatbot">Chatbot</MenuItem>
      </TextField>
</Grid>

<Grid item xs={6}>
<TextField
        fullWidth
        select
        label="Ticket Status"
        name="status"
        onChange={handleInputModChange}
        required="true"
        sx={{marginTop:"5px"}}
        value={selectedItem.status || ""}
      >
        <MenuItem value="">Select Ticket Status</MenuItem>
        <MenuItem value="Open">Open</MenuItem>
        <MenuItem value="In Progress">In Progress</MenuItem>
        <MenuItem value="Resolved">Resolved</MenuItem>
        <MenuItem value="Reopened">Reopened</MenuItem>
        <MenuItem value="Escalated">Escalated</MenuItem>
        <MenuItem value="Awaiting Customer Feedback">Awaiting Customer Feedback</MenuItem>
      </TextField>
</Grid>

<Grid item xs={6}>
<TextField
        fullWidth
        select
        label="Ticket Priority"
        name="priority"
        onChange={handleInputModChange}
        required="true"
        sx={{marginTop:"5px"}}
        value={selectedItem.priority || ""}
      >
        <MenuItem value="">Select Ticket Priority</MenuItem>
        <MenuItem value="Critical">Critical ( P 1 )</MenuItem>
        <MenuItem value="High">High ( P 2 )</MenuItem>
        <MenuItem value="Medium">Medium ( P 3 )</MenuItem>
      </TextField>
</Grid>

<Grid item xs={12}>
<TextField
        fullWidth
        select
        label="Assign To"
        name="mail_to"
        onChange={handleInputChange}
        required="true"
        sx={{marginTop:"5px"}}
        value={selectedItem.assigned_to || ""}
      >
        <MenuItem value="" disabled>Select</MenuItem>
        <MenuItem value="jzakayo@visionafrikasacco.co.ke">Marketing</MenuItem>
        <MenuItem value="kipkirui@visionafrikasacco.co.ke">Operations</MenuItem>
        <MenuItem value="btheuri@visionafrikasacco.co.ke">IT</MenuItem>
        <MenuItem value="susankamau@visionafrikasacco.co.ke">Susan Kamau</MenuItem>
        <MenuItem value="finance@visionafrikasacco.co.ke">Finance</MenuItem>
        <MenuItem value="jkionjogu@visionafrikasacco.co.ke">Credit Mgr</MenuItem>
        <MenuItem value="awambugu@visionafrikasacco.co.ke">Branch Operations Officer</MenuItem>
        <MenuItem value="njagi@visionafrikasacco.co.ke">BM nakuru</MenuItem>
        <MenuItem value="ngure@visionafrikasacco.co.ke">BM Gilgil</MenuItem>
        <MenuItem value="zmugoya@visionafrikasacco.co.ke">BM Molo</MenuItem>
        <MenuItem value="sammyk@visionafrikasacco.co.ke">BM Naivasha</MenuItem>
        <MenuItem value="maureen@visionafrikasacco.co.ke">Operations Officer Molo</MenuItem>
        <MenuItem value="jngutwa@visionafrikasacco.co.ke">Operations Officer Gilgil</MenuItem>
        <MenuItem value="nkagai@visionafrikasacco.co.ke">Operations Officer Naivasha</MenuItem>
        <MenuItem value="pwanjohi@visionafrikasacco.co.ke">Recovery Mgr</MenuItem>
      </TextField>
</Grid>


<Grid item xs={12}>
          <TextField
            multiline
            rows={3}
            label="Ticket Description"
            placeholder="Ticket Description"
            variant="outlined"
            fullWidth
            name="description"
            onChange={handleInputModChange}
            sx={{marginTop:"5px"}}
            value={selectedItem.description || ""}
          />
        </Grid>

        <Grid item xs={12} sx={{display:"space-between"}}>
        <Box sx={{display: 'flex', justifyContent: 'space-between', width: '100%' }}>
          <Button type="submit" variant="contained" sx={{padding:"10px",marginTop:"10px",color:'white',backgroundColor: colors.blueAccent[600]}}>Update Ticket</Button>
          <Button type="button" variant="contained" sx={{padding:"10px",marginTop:"10px",color:'white',backgroundColor: colors.blueAccent[700]}} onClick={() => handleAddCommentClick(selectedItem.ticket_id)}>Add Comment</Button>
          <Button type="button" variant="contained"  onClick={() => handleCommentListClick(selectedItem.ticket_id)} sx={{padding:"10px",marginTop:"10px",color:'white',backgroundColor: colors.blueAccent[800]}}>Comments</Button>
          <Button type="button" variant="contained" onClick={() => setNewTickeEdittOpen(false)} sx={{padding:"10px",marginTop:"10px",color:'white',backgroundColor: 'red'}}>Close</Button>
          </Box>

            </Grid>

            </Grid>
          )}
          </form>
        </div>
        </Box>
      </Modal>


      <Modal
        open={commentopen}
        onClose={() => setCommentOpen(false)}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box backgroundColor={colors.primary[700]} sx={style}>
        <div className="modal-content">
            <Box sx={{display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <h3 id="modal-title">Add Comment</h3>
            <Box display="flex" justifyContent="right">
        <CancelIcon onClick={() => setCommentOpen(false)} sx={{cursor:"pointer"}}/>
        </Box>
            </Box>
<form onSubmit={handleNewCommentSubmit}>
            <TextField
            multiline
            rows={3}
            label="Ticket Comment"
            placeholder="Ticket Comment"
            variant="outlined"
            fullWidth
            name="comment"
            onChange={handleInputChange}
            sx={{marginTop:"5px"}}
          />
          
        
          <TextField    
              type="hidden"
              name="ticket_id"
              value={selectedItemComment}
              style={{ display: 'none' }}
              required="true"
            />
            {selectedItem && (
                      <TextField    
              type="hidden"
              name="user_name"
              value={selectedItem.user_name || ""}
              style={{ display: 'none' }}
              required="true"
            />)}
    
          <Button type="submit" variant="contained" sx={{padding:"10px",marginTop:"12px",color:'white',backgroundColor: colors.blueAccent[600]}}>Submit Comment</Button>
          </form>
            </div>
            </Box>
            </Modal>


            <Modal
        open={commentlistopen}
        onClose={() =>setCommentListOpen(false)}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box backgroundColor={colors.primary[700]} sx={style}>
        <div className="modal-content">
            <Box sx={{display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <h3 id="modal-title">Comment List For Ticket No #{selectedItem && (selectedItem.id)}</h3>
            <Box display="flex" justifyContent="right">
        <CancelIcon onClick={() => setCommentListOpen(false)} sx={{cursor:"pointer"}}/>
        </Box>
            </Box>


<Box sx={{height:'300px',overflowY:'auto'}}>
{comments.map((cmmnts,i) => (
  <Box variant="h5" p={2} m={1} sx={{backgroundColor: colors.primary[600], borderRadius:'5px'}}><Box><b>{cmmnts.user_name}</b> <br/> {cmmnts.comment}</Box> <br/> <Box display='flex' justifyContent='right'>{cmmnts.created_at.slice(0, -16).replace('T', ' ')}</Box></Box>
))}
</Box>

    

            </div>
            </Box>
            </Modal>




    </div>

  </Box>
  );
}

export default Ticketing;



