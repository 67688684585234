import React, { useState } from 'react';
import { TextField, Button, Container, Box, Card, Typography} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { tokens } from "../theme";
import axios from 'axios';
import { global_link } from '../Components/Globallink';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import backgroundImage from '../images/bg_4.png';

const api = axios.create({baseURL: global_link});

const Login = () => {
const navigate = useNavigate();
const theme = useTheme();
const colors = tokens(theme.palette.mode);
const [formData, setFormData] = useState({});
const [isLoggedIn, setIsLoggedIn] = useState(false);
const [open, setOpen] = useState(false);
const handleClose = (event, reason) => {
if (reason === 'clickaway') {return;}setOpen(false);}; 
const [state, setState] = useState({open: false,vertical: 'top',horizontal: 'center',});
const { vertical, horizontal} = state;

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


  const handleLogin = (event) => {
    event.preventDefault();
    api.post('/userlogin/', 
    {
      user_email: formData.email,
      user_pass: formData.password
    }
    ,{ withCredentials: true})
      .then((response) => {
        localStorage.setItem('access_token', response.data.access_token);
        const accessToken = localStorage.getItem('access_token');
        const headers = {
          'Authorization': `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        };
        api.get('/user/', {
          headers,
          withCredentials: true,
        })
          .then(response => {
            sessionStorage.setItem("extension",response.data.user_extension);
            sessionStorage.setItem("extension_pass",response.data.user_extension_pass);
            sessionStorage.setItem("extension_ip",response.data.user_ip);
            sessionStorage.setItem("user_level",response.data.user_level);
            sessionStorage.setItem("user_name",response.data.user_name);
            localStorage.setItem("calldisabled",false);
            if(response.data.user_extension!=='112233'){
              window.location.href = '/';
            }else{
              window.location.href = '/ldash';
            }
          });
          
      })
      .catch((error) => {
        console.log(error);
        setOpen(true);
      });
  };


  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };


  return (
    <div
    style={{
      backgroundImage: `url(${backgroundImage})`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      height: "auto",
    }}
  >
    <Box>

    
    <Container maxWidth="xs"      
    sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
      
    }}>
      <Card sx={{padding:"20px", background:colors.primary[700]}}>
        <Typography textAlign="center" variant='h5'>LOGIN</Typography>
      <form onSubmit={handleLogin}>

      <TextField
          placeholder="Email"
          variant="outlined"
          fullWidth
          name="email"
          onChange={handleInputChange}
          margin="normal"
          required="true"
          type='email'
        />

<TextField
          placeholder="Password"
          variant="outlined"
          fullWidth
          name="password"
          onChange={handleInputChange}
          margin="normal"
          required="true"
          type='password'
        />

        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={handleLogin}
          type="submit"
          sx={{padding:'10px',marginTop:'12px',background: colors.greenAccent[500] }}

        >
          Login
        </Button>
      </form>

{/* <Typography textAlign="center" marginTop='10px'>
  <a href="https://noderizon.com" target='_blank' style={{ textDecoration: 'none' , color: 'inherit', fontSize: '10px'}}>
    Powered By <b>NodeRizon</b>
  </a>
</Typography> */}
      </Card>
    </Container>

    <Stack spacing={2} sx={{ width: '100%'}}>
  <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}  anchorOrigin={{ vertical, horizontal }} key={vertical + horizontal}>
  <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
 Wrong Email Or Password
  </Alert>
  </Snackbar>
  </Stack>

<Stack spacing={2} sx={{ width: '100%' }}>
  <Snackbar open={false} onClose={handleClose} anchorOrigin={{ vertical, horizontal }} key={vertical + horizontal}>
    <Alert severity="error" sx={{ width: '100%' }}>
      YOU HAVE AN OUTSTANDING PAYMENT
    </Alert>
  </Snackbar>
</Stack>

    </Box>
    </div>
  );
};

export default Login;
